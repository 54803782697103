








































































import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class Footer extends Vue {
  @Prop({ default: true }) readonly showTrial?: Boolean;

  year = new Date().getFullYear();
}

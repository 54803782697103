







































































































































import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class NavBar extends Vue {
  drawer: boolean = false;

  @Prop({ default: false }) readonly showOnlyLogo?: Boolean;
}

import Vue, { ComponentOptions, PluginObject, VueConstructor } from 'vue';

export const Tawk: PluginObject<any> = {
  install (_: VueConstructor, pluginOptions: ComponentOptions<Vue>): void {
    const s1 = document.createElement('script');
    const s0 = document.getElementsByTagName('script')[0];
    if (s0 === null || s0.parentNode === null) return;

    s1.async = true;
    s1.src = pluginOptions['url'];
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }
};

if (process.env.DEBUG === 'false') {
  Vue.use(Tawk, { url: `${process.env.TAWK_URL}` });
}